/* Home Page */
/* Custom button, base defined here */
/* Modified in each component */
.btn-custom {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #181818;
  background-color: #f7e00c;
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:active,
.btn-custom.active {
  color: #000;
  background-image: none;
  background-color: #ffffff;
}
.btn-custom:focus,
.btn-custom.focus {
  color: #181818;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
