/* Custom Modal */
.modal-title {
  font-size: 25px;
  font-weight: bold;
}
.modal-content {
  border-radius: 5px !important;
  background-color: #fff !important;
}
.modal-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #f7e000;
  color: #181818;
}
.modal-footer {
  justify-content: center !important;
  margin-bottom: 15px;
}
.modal-footer .btn-primary {
  background-color: #f7e000;
  color: #181818;
  font-size: 18px;
  text-transform: uppercase;
  padding: 14px 100px;
  border: none;
  border-radius: 25px;
  transition: all 0.5s linear;
}
.modal-footer .btn-primary:hover {
  background-color: #181818;
  color: #fff;
}
.modal-footer .btn-primary:focus {
  background-color: #181818;
  color: #fff;
}
.modal-footer .btn-primary:active {
  background-color: #181818;
  color: #fff;
}
