/* Products & Services Section */
#products-services {
  background: #eaeaea;
  font-family: "Lato", "sans-serif";
}
#products-services .ps-country {
  height: 350px;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 0px 15px 0px 15px;
  align-content: center;
  background-image: url("assets/argentina-map.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
#products-services h2 {
  color: #3f4443;
  margin-bottom: 20px;
}
#products-services h2::after {
  color: #f7e000;
}
#products-services .section-title h3 {
  font-size: 24px;
  font-weight: normal;
  font-style: italic;
  color: #3f4443;
  margin-bottom: 20px;
}
#products-services p {
  color: #3f4443;
  margin-bottom: 50px;
}
#products-services .ps-title {
  font-family: "Quivera-Regular", "sans-serif";
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#products-services .ps-title span:first-of-type {
  color: #f7e000;
}
#products-services .ps-title span:last-of-type {
  color: #3f4443;
}
#products-services .ps-title-desc {
  color: #3f4443;
  font-family: "Lato", "sans-serif";
  font-size: 25px;
  margin-top: 25px;
}
#products-services .ps-equipment-container {
  width: 100%;
  position: relative;
}
#products-services .ps-equipment-text {
  width: 100%;
  color: white;
  font-family: "Lato", "sans-serif";
  font-weight: 700;
  text-transform: uppercase;

  position: absolute;
  top: 50;
}
#products-services .ps-equipment-text-1 {
  font-size: min(5.5vw, 66px);
}
#products-services .ps-equipment-text-1 span:nth-child(2){
  color: #f7e000;
}
#products-services .ps-equipment-text-2 {
  font-size: min(4.5vw, 54px);
}
#products-services .ps-equipment-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#products-services i.fa {
  font-size: 38px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 100px;
  height: 100px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #f7e00cbf 0%, #f7e000 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
