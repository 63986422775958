/* Contact Section */
#contact {
  font-family: "Lato", "sans-serif";
  padding: 100px 0 0 0;
  background: linear-gradient(to right, #181818 0%, #181818 100%);
  color: rgba(255, 255, 255, 0.75);
}
#contact .section-title {
  margin-bottom: 40px;
}
#contact .section-title p {
  font-size: 18px;
}
#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: #f7e000;
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}
#contact h3 {
  font-size: 24px;
  color: #fff;
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}
#contact form {
  padding-top: 20px;
}
#contact .text-danger {
  color: #cc0033;
  text-align: left;
}
#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  border-color: #f7e000;
}
#contact .btn-custom:hover {
  color: #000;
  background: #fff;
  border-color: #fff;
}
#contact-info .contact-item {
  margin: 20px 0;
}
#contact-info .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}
#contact-info .contact-item i.fa {
  margin-right: 10px;
}
#contact-info a {
  color: rgba(255, 255, 255, 0.75);
}
#contact-info a:hover {
  text-decoration: underline;
}
#contact-info a:visited {
  color: rgba(255, 255, 255, 0.75);
}
#contact .help-block {
  color: rgba(255, 255, 255, 0.75);
}
#contact textarea {
  resize: none;
}
#contact .input-group-addon {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
#contact .form-control {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
#contact #sent .icon-container,
#contact #error .icon-container,
#contact #sending .icon-container {
  margin-top: 80px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
#contact #sent .icon-font-size,
#contact #error .icon-font-size,
#contact #sending .icon-font-size {
  font-size: 128px;
}
#contact #sent .caption,
#contact #error .caption,
#contact #sending .caption {
  color: rgba(255, 255, 255, 0.75);
  padding-top: 10px;
}
#contact #sent .caption p,
#contact #error .caption p,
#contact #sending .caption p {
  font-size: 16px;
}
