/* 404 Error custom page */ 
#alientech-ar-not-found #footer {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
#notfound {
  position: relative;
  background-color: #181818;
  height: 100vh
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}
.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center
}
.notfound .notfound-404 {
  position: relative;
  height: 240px
}
.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  font-size: min(55vw, 240px);
  font-weight: 900;
  margin: 0;
  color: #eaeaea;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px
}
.notfound .notfound-404 h1>span {
  text-shadow: -8px 0 0 #181818
}
.notfound .notfound-404 h3 {
  font-family: "Lato", sans-serif;
  position: relative;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #eaeaea;
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px
}
.notfound h2 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  color: #eaeaea;
  margin-top: 0;
  margin-bottom: 25px
}
