/* Modal Branch Data */
#modal-branch-data {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  #modal-branch-data tr {
    display: inline-block;
    width: 100%;
    color: #181818;
    font-size: 18px;
    margin-bottom: 15px;
  }
  #modal-branch-data tr td:first-child {
    width: 50px;
    text-align: center;
    vertical-align: middle;
  }
  #modal-branch-data tr td:nth-child(2) {
    width: 95px;
    font-weight: bold;
    vertical-align: middle;
  }
  #modal-branch-data tr td:nth-child(3) {
    white-space: pre-line;
    vertical-align: middle;
  }
  #modal-branch-data td {
    width: auto;
  }
  #modal-branch-data i {
    display: inline-block;
    margin-right: 10px;
    font-size: 22px;
  }
  