/* Footer Section*/
#footer {
  font-family: "Lato", "sans-serif";
  background: linear-gradient(to right, #181818 0%, #181818 100%);
  color: rgba(255, 255, 255, 0.75);
}
#footer .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 50px 0 60px 0;
  margin-top: 50px;
  text-align: center;
}
#footer .social ul li {
  display: inline-block;
  margin: 0 20px;
}
#footer .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #f7e000;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}
#footer .social i.fa:hover {
  color: #000;
  border: 2px solid #fff;
  background: #fff;
}
#footer #copyright {
  background: #121212;
  padding: 30px 0;
}
#footer #copyright p {
  font-family: "Lato", sans-serif;
  color: #ffffff;
  font-size: 15px;
}
