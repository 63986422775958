/* Product */
#products-services .ps-items-container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-items: center;
    column-gap: 75px;  
    row-gap: normal;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    padding: 0px 15px 0px 15px;  
  }
  @media (max-width: 1220px) {
    #products-services .ps-items-container {
      flex-wrap: wrap;
      column-gap: normal;
      row-gap: 75px;
  }}
  #products-services .ps-items-image{
    width: 250px;
    height: 250px;
    border-radius: 10px;
  }
  