/* Academy */
#academy .academy-img {
    width: 240px;
  }
  #academy .academy-sep {
    margin-right: 10px;
  }
  #academy .thumbnail {
    background: transparent;
    border: 0;
  }
  #academy .thumbnail .caption {
    padding: 10px 0 0;
    color: #888;
    margin-bottom: 15px;
  }
  #academy .thumbnail .caption h4 {
    min-height: 40px;
  }
  #academy .thumbnail .caption p {
    font-weight: bold;
  }
  #academy .thumbnail a {
    color: black;
    font-weight: bolder;
  }
  #academy .btn-custom {
    color: #181818;
  }
  #academy .btn-custom:hover,
  #academy .btn-custom:focus,
  #academy .btn-custom.focus,
  #academy .btn-custom:active,
  #academy .btn-custom.active {
    color: #fff;
    background-color: #181818;
  }
  