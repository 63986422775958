/* Academy Section */
#academy {
  padding: 100px 0;
  font-family: "Lato", "sans-serif";
  color: #3f4443;
}
#academy .academy-item {
  display: inline-block;
  padding: 0px 15px 0px 15px;
}
#academy h3 {
  font-size: 24px;
  font-weight: normal;
  font-style: italic;
  color: #3f4443;
  margin-bottom: 20px;
}
#academy .section-title p {
  font-size: 18px;
}
#academy h4 {
  margin: 5px 0;
}
#academy #loading,
#academy #norecords {
  margin-top: 50px;
}
#academy #loading .caption, 
#academy #norecords .caption {
  color: #3f4443;
}
#academy #loading .caption p,
#academy #norecords .caption p {
  font-size: 16px;
}
#academy #loading .icon-font-size,
#academy #norecords .icon-font-size {
  font-size: 128px;
}
