/* Header Section */
.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0;
  background-color: #eaeaea;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.intro .overlay {
  width: 100%;
  background: #eaeaea;
}
.intro .video-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  background-color: #000;
}
.intro .video-overlay video {
  width: 100%;
  height: 100vh;
  display: block;
  object-fit: cover;
  background-size: cover;
  opacity: 0.6;
}
.intro h1 {
  font-size: min(8.5vw, 50px);
}
.intro h2 {
  font-size: min(5.2vw, 30px);
}
.intro h1, h2 {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.intro h1, h2 span {  
  font-weight: 800;
  color: #ffffff;
}
.intro .title-accent {
  color: #f7e000;
}
.intro p {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: min(4.2vw, 22px);
  font-weight: 300;
  line-height: 30px;
  max-width: 750px;
}
.intro .btn-custom {
  margin-top: 60px;
}
