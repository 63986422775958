/* Resellers Section */
#resellers p {
  margin-bottom: 40px;
  color: #3f4443;
}
#resellers .map-container {
  position: relative;
}
#resellers .map-container-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000000AA;
}
#resellers .map-container-overlay span {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-size: 128px;
}
#resellers .map-container-overlay span div p {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 0px;
}
#resellers .map-custom-marker {
  height: 35px;
  width: 35px;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-image: "./assets/marker_icon.webp";
  background-color: #181818;  
}
#resellers .map-custom-marker:hover {
  background-color: #eaeaea;
}
